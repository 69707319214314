/* Variables */
:root {
  --primary-color: #fe7128;
  --secondary-color: #bac964;
  --dark-color: #222;
  --light-color: #fff;
  --gray-color: #333;
}

/* Base styles */
html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 60px;
}

.container {
  padding: 0 50px;
  width: 100%;
  margin: 0 auto;
}

/* Hero Section */
.site-hero {
  background-image: url('../assets/main-image.jpg');
  background-size: cover;
  background-position: 80%;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

.site-hero:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.site-hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.site-hero-text strong {
  font-weight: 800;
  font-size: 1.5em;
}

.site-hero-text p {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.site-hero-text span {
  color: var(--primary-color);
}

/* Projects Section */
#projects, #devops {
  padding: 25px 0;
  text-align: center;
}

.project-box, .devops-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  margin: 25px 75px;
  position: relative;
  max-width: 90vw;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
}

.project-box img, .devops-box img {
  max-height: 300px;
  margin-bottom: 2rem;
  width: auto;
}

.view-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white !important;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  text-transform: none !important;
  border: none;
}

.view-button:hover {
  background-color: #ff8c5a !important;
  color: white !important;
  text-decoration: none !important;
}

/* Override general anchor hover for view-button */
a.view-button:hover {
  color: white !important;
  text-decoration: none !important;
}

.button-container {
  margin-top: 1rem;
}

@media only screen and (min-width: 1024px) {
  #projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .project-box, .devops-box {
    margin: 25px 25px;
  }
}

/* Resume Section */
#resume {
  padding: 25px 0;
  background-color: var(--dark-color);
  color: white;
  font-size: 1.1rem;
}

#resume h2 {
  margin-bottom: 25px;
}

.orange-font {
  color: var(--primary-color);
}

.resume-container {
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.resume-education,
.resume-experience {
  margin: 20px;
}

.resume-box {
  background: #fff;
  padding: 30px;
  margin-bottom: 25px;
  border-radius: 7px;
  transition: 0.3s all ease;
  border: 4px solid transparent;
  color: #333;
}

.resume-box h3 {
  color: var(--secondary-color);
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.resume-box p {
  color: #4a5568;
}

.resume-box p:first-of-type {
  font-weight: 600;
  color: #2d3748;
}

.resume-box p:last-of-type {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}

.resume-education h3,
.resume-experience h3 {
  color: #fff;
}

.resume-education > h3,
.resume-experience > h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.resume-education h3:not(:first-child) {
  margin: 30px 0 20px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .resume-container {
    grid-template-columns: 1fr;
  }
}

/* About Me Section */
#about-me {
  padding: 25px 0;
}

@media only screen and (min-width: 1024px) {
  #about-me {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

#about-me img {
  max-width: 100%;
}

.about-me-box {
  max-width: 100%;
  padding: 25px;
  font-size: 1.1rem;
}

.about-me-box a {
  margin-right: 15px;
}

.headline {
  margin-bottom: 1rem;
}

/* Contact Section */
#get-in-touch {
  max-width: 100%;
  background-color: var(--dark-color);
  color: white;
  font-size: 1rem;
  margin: 0;
  padding: 50px 0;
}

#get-in-touch li {
  list-style: none;
}

.my-form {
  padding: 0 75px;
}

.green-font {
  color: var(--secondary-color);
}

.submit-button {
  background-color: var(--primary-color);
  border-radius: 4px;
  border-color: transparent;
  margin: 25px 0;
  text-transform: none !important;
  padding: 12px 24px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff8c5a !important;
  border-color: transparent;
}

@media (max-width: 768px) {
  .submit-button {
    display: block;
    margin: 25px auto;
    width: fit-content;
  }
  
  .my-form {
    text-align: center;
  }
}

/* Social Icons */
.social-icons {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.social-item {
  color: var(--secondary-color) !important;
  font-size: 2rem;
  display: inline-block;
  transition: color 0.3s ease;
}

.social-item:hover {
  color: var(--primary-color) !important;
  text-decoration: none;
}

.social-item i {
  width: 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .social-icons {
    justify-content: center;
  }
  
  .social-item {
    text-align: center;
  }
}

/* Form Styles */
.form-group {
  margin: 25px 0;
}

.row {
  margin-bottom: 0;
}

/* Modal Styles */
.modal {
  top: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header .close {
  margin-left: 20px;
  padding: 0 10px;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.modal-header .close:hover {
  opacity: 1;
}

.list-group {
  margin-bottom: 15px;
}

.list-group-item {
  padding: 0.75rem 1.25rem;
}

a {
  color: #8a9c3b;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

/* Remove any Bootstrap button overrides */
.btn {
  border-radius: 4px !important;
}

.about-me-box .view-button {
  border-radius: 4px !important;
}

/* Add pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(254, 113, 40, 0.7), 0 0 0 0 rgba(186, 201, 100, 0.5);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(254, 113, 40, 0), 0 0 0 15px rgba(186, 201, 100, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(254, 113, 40, 0), 0 0 0 0 rgba(186, 201, 100, 0);
  }
}

.view-button.pulse {
  animation: pulse 1s ease-in-out;
}

.site-contact-details {
  list-style: none;
  padding: 0;
}

@media (max-width: 768px) {
  .site-contact-details {
    text-align: center;
  }

  .site-contact-details li {
    margin-bottom: 1rem;
  }

  #get-in-touch h3 {
    text-align: center;
  }
}

.modal-lg {
  max-width: 900px;
}

.modal-body h6.text-primary {
  color: var(--primary-color) !important;
  font-weight: 600;
  font-size: 1.1rem;
}

.modal-body .font-weight-bold {
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.experience-section {
  margin-bottom: 1.5rem;
}

.experience-section .list-group-item {
  border: none;
  padding: 0.5rem 1rem;
  background: transparent;
  color: #666;
}

.experience-section p {
  margin-bottom: 0.5rem;
}

.skills-section {
  padding: 1rem;
}

.skills-section p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.skills-section .font-weight-bold {
  color: var(--secondary-color);
  margin-right: 0.5rem;
}

.modal-body {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 1rem;
}

.modal-dialog {
  margin: 0 auto;
  padding-bottom: 20px;
}

/* Navigation Styles */
.navbar {
  padding: 1rem;
  transition: all 0.3s ease;
}

.navbar-brand {
  margin-right: auto;
}

.navbar-collapse {
  text-align: right;
}

.navbar-nav {
  margin-left: auto;
}

.nav-link {
  color: var(--light-color) !important;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-color) !important;
}

@media (max-width: 768px) {
  .navbar {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
  }
  
  .navbar-nav {
    text-align: right;
    width: 100%;
  }

  .nav-menu {
    width: 100%;
    text-align: right;
  }
  
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    text-align: right;
  }

  .nav-link {
    width: 100%;
    text-align: right;
    padding: 10px 15px !important;
  }

  .navbar-toggler {
    margin-left: auto;
  }

  .navbar-brand {
    margin-right: 0;
  }

  /* Target the specific li elements */
  .nav-menu li {
    width: 100%;
    text-align: right;
    display: block;
  }

  /* Target the specific anchor elements */
  .nav-menu li a {
    text-align: right;
    display: block;
    width: 100%;
    padding: 10px 15px;
  }
}

/* Override Bootstrap defaults */
.navbar-nav,
.nav-menu,
.nav-item,
.nav-link {
  text-align: right !important;
}

.nav-menu.active {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
} 