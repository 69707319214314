#about-me {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  gap: 2rem;
}

.about-me-box {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
}

.about-me-box img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-me-box h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-me-box .headline {
  color: #666;
  margin-bottom: 1.5rem;
}

.about-me-box p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.orange-font {
  color: #ff6b35;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #ff6b35;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #ff8c5a;
}

.btn-outline-primary {
  background-color: transparent;
  color: #ff6b35;
  border: 2px solid #ff6b35;
}

.btn-outline-primary:hover {
  background-color: #ff6b35;
  color: white;
}

@media (max-width: 768px) {
  #about-me {
    padding: 2rem 0;
  }
  
  .about-me-box {
    text-align: center;
  }
  
  .about-me-box h2 {
    font-size: 2rem;
  }
} 