/* Component-specific styles that override or extend site.css */

/* Hero section overrides */
.site-hero {
  background-image: url('../assets/main-image.jpg');
  background-size: cover;
  background-position: 80%;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

.site-hero:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.site-hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.site-hero-text strong {
  font-weight: 800;
  font-size: 1.5em;
}

.site-hero-text p {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.site-hero-text span {
  color: var(--primary-color);
}

/* Projects section overrides */
.projects-box {
  padding: 50px 0;
  margin: 25px 75px;
  position: relative;
  max-width: 90vw;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  text-align: center;
}

.projects-box img {
  max-height: 300px;
}

.projects-box button {
  margin-top: 25px;
}

/* Resume section overrides */
.resume-box {
  padding: 30px;
  background: var(--gray-color);
  transition: 0.3s all ease;
  border: 4px solid transparent;
  border-radius: 7px;
  margin-bottom: 25px;
}

.resume-box h3 {
  color: var(--secondary-color);
}

.resume-box p:nth-child(1) {
  font-weight: 600;
}

.resume-box p:last-child {
  opacity: 0.4;
}

/* About me section overrides */
.about-me-box {
  max-width: 100%;
  padding: 25px;
  font-size: 1.1rem;
}

.about-me-box a {
  text-transform: uppercase;
  border-radius: 100px;
}

.about-me-box a:first-child {
  background-color: var(--primary-color);
  border-color: transparent;
  font-weight: 600;
}

.about-me-box a:first-child:hover {
  background-color: var(--secondary-color);
}

.headline {
  font-size: 1.3em;
}

/* Contact section overrides */
.submit-button {
  background-color: var(--primary-color);
  border-radius: 100px;
  text-transform: uppercase;
  border-color: transparent;
  margin: 25px 0;
}

.submit-button:hover {
  background-color: var(--secondary-color);
  border-color: transparent;
}

.social-item {
  color: var(--secondary-color) !important;
  font-size: 2rem;
}

.social-item i {
  margin: 25px 0;
  padding-right: 50px;
}

/* Form overrides */
.form-group {
  margin: 25px 0;
}

.row {
  margin-bottom: 0;
}

/* Modal overrides */
.modal {
  top: 100px;
}

.list-group {
  margin-bottom: 25px;
}

/* Responsive overrides */
@media (max-width: 768px) {
  .resume-container,
  #about-me {
    grid-template-columns: 1fr;
  }

  .site-hero-text h1 {
    font-size: 2.5rem;
  }
} 