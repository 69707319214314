/* Import Bootstrap */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Import Font Awesome */
@import '~@fortawesome/fontawesome-free/css/all.min.css';

/* Import custom styles */
@import './site.css';

/* Override Bootstrap defaults */
body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

#root {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
}

/* Reset container padding */
.container {
  padding: 0;
  margin: 0;
  max-width: none;
  width: 100%;
} 