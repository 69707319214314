#resume {
  padding: 4rem 0;
}

.text-center {
  text-align: center;
}

.resume-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
}

.resume-experience, .resume-education {
  flex: 1;
  min-width: 300px;
}

.resume-box {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resume-box h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.resume-box p {
  margin-bottom: 0.5rem;
  color: #666;
}

.green-font {
  color: #28a745;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.orange-font {
  color: #ff6b35;
}

@media (max-width: 768px) {
  #resume {
    padding: 2rem 0;
  }
  
  .resume-container {
    flex-direction: column;
  }
} 