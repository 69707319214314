.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem 0;
  backdrop-filter: blur(5px);
  transition: background-color 0.3s ease;
}

.navbar:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.nav-item {
  padding: 0;
  margin: 0;
}

.nav-link {
  color: white !important;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  transition: color 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  color: var(--primary-color) !important;
  text-decoration: none;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
}

.hamburger-line {
  display: block;
  width: 25px;
  height: 2px;
  margin: 5px auto;
  background-color: #ff6b35;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}

@media (max-width: 768px) {
  .navbar {
    background-color: transparent;
    backdrop-filter: none;
  }

  .navbar:hover {
    background-color: transparent;
  }

  .nav-container {
    padding: 0;
  }

  .hamburger {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1.5rem;
  }

  .nav-menu {
    display: flex;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(5px);
    padding: 2rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    gap: 2rem;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  }

  .nav-menu.active {
    visibility: visible;
    opacity: 1;
    right: 0;
  }

  .nav-link {
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    transform: translateX(50px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .nav-menu.active .nav-link {
    transform: translateX(0);
    opacity: 1;
    transition-delay: 0.2s;
  }

  /* Hamburger animation */
  .hamburger.active .hamburger-line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  .hamburger.active .hamburger-line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .hamburger-line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
} 